.card {
  background: $white;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 #33334f05;
  padding: 10px 10px 20px;
  margin: 10px 0 30px;

  h2 {
    font-size: 20px;
    line-height: 1.35;
    font-weight: 600;
    margin: 10px 8px;

    @media (max-width: 400px) {
      font-size: 18px;
      margin: 0;
    }
  }
}

.card-small-length {
  margin-bottom: 70px;
  @media (max-width: 810px) {
    margin-bottom: 30px;
  }
}

.card-body {
  @media (min-width: 400px) {
    padding: 1.25rem;
  }
}

.stats {
  img {
    overflow: hidden;
    border-radius: 10px;
  }

  @media (min-width: 660px) {
    display: flex;
    justify-content: space-between;

    strong {
      display: block;
      white-space: nowrap;

      @media (max-width: 860px) {
        font-size: 17px;
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 660px) {
    flex-direction: column;
    overflow: hidden;

    strong {
      margin-left: 10px;
    }
  }
}

.stats-col {
  padding-right: 10px;

  @media (max-width: 860px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: 660px) {
    width: 220px;
    float: left;

    &:first-child {
      width: 150px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }
  }


  @media (max-width: 430px) {
    margin-bottom: 5px;

    &:first-child {
      height: 60px;
      line-height: normal;
      text-align: left;
    }
  }
}

@media (max-width: 700px) {
  .card-header {
    display: flex;
    flex-direction: column-reverse;
  }
}

.seller {
  padding: 20px;

  .page-title {
    font-size: 25px;
  }

  p {
    font-size: 18px;
  }

  ul {
    margin: 20px 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.8;
    list-style: none;

    @media (max-width: 1200px) {
      font-size: 17px;
    }

    @media (max-width: 640px) {
      font-size: 16px;
    }
  }

  li {
    position: relative;
    padding-left: 25px;

    &:before {
      content: "—";
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  @media (max-width: 860px) {
    flex-direction: column;
  }

  @media (max-width: 860px) {
    max-width: 640px;
    margin: auto;
  }
}
