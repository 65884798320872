.intro {
  padding: 30px 50px 0;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  h4 {
    font-size: 24px;
    margin-bottom: 10px;
    max-width: 650px;
    line-height: 1.5;
    font-weight: bold;
    margin-top: 32px;
  }

  p {
    max-width: 650px;
    margin-bottom: 10%;
  }

  a {
    text-decoration: none;
  }

  .bg {
    max-width: 730px;
  }

  @include media-breakpoint-down(md) {
    padding: 20px 20px 0;

    h4 {
      font-size: 18px;
      line-height: normal;
    }

    p {
      margin-top: 0;
    }

    .bg {
      margin-left: -20%;
      width: 120%;
      max-width: 120%;
    }
  }

  @media (max-width: 800px) {
    h4 {
      font-size: 16px;
      margin-top: 10px;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 15px 15px 0;
    text-align: center;
    max-width: 450px;
    margin: auto;

    h4 {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 600;
    }

    p,
    .bg {
      display: none;
    }
  }

  @media (max-width: $grid-breakpoints-xxs) {
    h4 {
      font-size: 14px;
      font-weight: 400;
      clear: both;
      text-align: left;
      padding-top: 10px;
    }
  }
}

.intro-a,
.intro-b {
  max-width: 700px;
  padding: 0 20px;
  margin: 0 auto;
  text-align: left;

  .logo {
    margin-top: 10%;
  }

  @include media-breakpoint-down(xxl) {
    max-width: 600px;
  }

  @include media-breakpoint-down(lg) {
    max-width: 535px;
  }

  @include media-breakpoint-only(md) {
    max-width: 380px;
  }
}

.intro {
  &-title {
    font-size: 28px;
    font-weight: 800;
    margin-top: 45px;

    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }

    .intro-b & {
      max-width: 500px;
    }
  }

  &-subtitle {
    max-width: 350px;
  }

  &-section {
    margin-bottom: 60px;

    &:last-of-type {
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 45px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  &-subhead {
    padding-bottom: 10px;
  }
}

.growths {
  list-style-type: none;
  margin: 25px 0 50px;
  padding: 0;
  font-size: 18px;
  max-width: 460px;

  li {
    overflow: hidden;
    padding: 9px 0;
    font-size: 18px;
    line-height: 24px;
  }

  .num {
    background: #fff;
    width: 95px;
    height: 48px;
    border-radius: 8px;
    float: left;
    margin-right: 20px;
    font-size: 26px;
    text-align: center;
    line-height: 48px;
    font-weight: bold;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
  }

  .plus-num {
    font-weight: normal;
    font-size: 24px;
  }

  @include media-breakpoint-down(lg) {
    max-width: 400px;

    li {
      font-size: 16px;
      line-height: 22px;
    }

    .num {
      font-size: 24px;
      height: 44px;
      line-height: 44px;
      width: 85px;
      margin-right: 15px;
    }

    .plus-num {
      font-size: 22px;
    }
  }

  @include media-breakpoint-down(md) {
    margin: 15px 0 30px;
    max-width: 385px;

    li {
      display: flex;
      padding: 6px 0;
    }

    .num {
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      min-width: 68px;
      max-width: 68px;
      border-radius: 5px;
      margin-right: 10px;
      margin-top: 4px;
    }

    .plus-num {
      font-size: 16px;
    }
  }
}

.clients {
  img {
    margin: 30px 40px 0 0;

    @include media-breakpoint-down(lg) {
      margin-top: 20px;
    }

    @include media-breakpoint-down(md) {
      margin: 15px 25px 0 0;
    }
  }
}

.residents {
  svg {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    &:first-of-type {
      @include media-breakpoint-down(md) {
        margin: 0 0 20px 0;
        width: 160px;
        height: auto;
        display: block;
      }
    }
  }

  &-inline {
    margin-top: 20px;

    p {
      padding-right: 20px;

      @include media-breakpoint-down(lg) {
        padding-bottom: 10px;
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }
  }
}

.signup-row {
  max-width: 1700px;
  margin: auto;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: stretch;
    min-height: 100%;
    width: 100%;
  }
}

.compact-form {
  background: #f6f4f5;
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 5vh;

  label {
    font-weight: normal;
    margin-bottom: 3px;

    @include media-breakpoint-up(xl) {
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
    line-height: 26px;
    margin: 0;

    @include media-breakpoint-down(lg) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .btn-lg {
    border-radius: 10px;
    height: 55px;
    line-height: 55px;

    @media (max-width: $grid-breakpoints-xxs) {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .form-signin {
    p {
      padding-bottom: 20px;
    }
  }

  .form-signin-heading {
    margin-bottom: 10px;

    @include media-breakpoint-up(xl) {
      margin-top: 35px;
    }

    @include media-breakpoint-down(xxl) {
      margin-top: 25px;
    }
  }

  .form-signin,
  .form-signup {
    border-radius: 18px;
    padding-bottom: 35px;
    text-align: left;
    margin: auto;
    max-width: 650px;

    @include media-breakpoint-down(xl) {
      max-width: 500px;
    }

    @include media-breakpoint-down(sm) {
      border-radius: 8px;
    }
  }

  .form-control {
    height: 55px;
    border-radius: 8px;

    @include media-breakpoint-down(sm) {
      height: 50px;
    }
  }

  .hint {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .secondary-action {
    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }

  .navigation {
    @media (max-width: $grid-breakpoints-xxs) {
      margin-top: -10px;
    }
  }

  .wrapper {
    @include media-breakpoint-between(sm, lg) {
      padding: 0 20px;
    }
  }

  @include media-breakpoint-up(xxl) {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}
