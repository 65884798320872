.search {
  &-field {
    border: solid 1px #ced4da;
    border-radius: 15px;
    height: 30px;
    padding: 0 10px;
    width: 400px;

    &:focus {
      box-shadow: none;
      border-color: $primary;
      outline: none;
    }

    @media (max-width: 700px) {
      width: 100%;
      margin: 10px 0;
    }
  }

  @media (min-width: 700px) {
    float: right;    
  }
}
