html {
  height: 100%;
  min-height: 100%;
}

.wrap {
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 960px;
    padding: 0 10px;
  }

  @media (max-width: 960px) {
    padding: 0 20px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.current-page {
  color: $black;
  pointer-events: none;
}

.breadcrumb {
  @media (max-width: 640px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.success {
  text-align: center;
  padding-top: 50px;
}

.success-view {
  max-width: 500px;
  margin: 20px auto;
  text-align: center;

  .icon,
  .icon-error {
    width: 160px;
    height: 160px;
    margin: 30px auto 20px;
    border-radius: 80px;
  }

  .icon {
    background: #ECF1FD;
    padding-top: 10px;
  }

  .icon-error {
    background: rgba($color-error, .2);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 400px) {
    .icon {
      height: 120px;
      width: 120px;
      margin: 20px auto 15px;
    }

    img {
      height: 90px;
    }

    h3 {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
}
