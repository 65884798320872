.preview {
  border-radius: 4px;
}

.table-compact {
  width: 100%;

  td {
    padding: 5px 7px 7px;
    font-size: 16px;
    vertical-align: top;
    line-height: normal;
    vertical-align: middle;
  }

  th {
    padding: 5px 7px 7px;
    font-weight: 600;
    background: $white;
    border-bottom: 1px solid #f0f0f0;
  }

  tr {
    &:hover {
      background: #f0f6ff;
    }
  }

  img {
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
  }

  .brand-name {
    white-space: nowrap;
  }

  @media (max-width: 640px) {
    th,
    td {
      padding: 3px 5px 5px;
      font-size: 14px;
    }
  }
}

.wide-table {
  overflow-x: auto;
  width: 100%;

  @media (max-width: 640px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.wide-table-small-length {
  @media (max-width: 810px) {
    overflow-x: auto;
    width: 100%;
  }
}

.pages {
  margin-top: 10px;

  @media (min-width: 730px) {
    text-align: right;
    overflow: hidden;
    padding-top: 20px;

    nav {
      float: left;
      margin-top: -5px;
    }
  }

  nav {
    @media (max-width: 730px) {
      margin-top: 20px;
    }
  }
}

.page-item {
  border-radius: 20px;
  overflow: hidden;
  text-align: center;

  @media (min-width: 400px) {
    min-width: 36px;
  }

  @media (max-width: 400px) {
    margin-right: 5px;
  }
}

.page-link {
  @media (max-width: 400px) {
    padding: 2px 8px;
  }
}

.first-page {
  display: none;
}

.disabled .page-link {
  background: none;
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: .5;
  pointer-events: none;
}

.paywall {
  background: $white;

  td {
    text-align: center;
    background: $white;
    position: relative;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: 10px;

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: -50px;
      height: 50px;
      width: 100%;
      content: "";
      background: rgb(255,255,255);
      background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    }
  }

  @media (max-width: 770px) {
    td {
      text-align: left;
    }

    h3 {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 510px) {
    h3 {
      font-size: 18px;
      margin-top: -5px;
      max-width: 350px;
    }
  }

  @media (max-width: 380px) {
    h3 {
      font-size: 16px;
      max-width: 300px;
    }
  }

  @media (max-width: 350px) {
    h3 {
      max-width: 275px;
    }
  }
}

.paywall-msg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 10px;
  text-align: center;

  @media (max-width: 414px) {
    text-align: left;

    h3 {
      margin-left: 0;
    }
  }
}

.paywall-msg-small-length {
  background: $white;
  border-radius: 10px;
  right: -10px;
  left: -10px;
  padding: 10px 0 10px 0;
  text-align: center;
}
