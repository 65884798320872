$secondary: #f6f4f5;
$primary: #0c50e9;
$white: #fff;
$black: #000;
$danger: red;
$dark: #888;
$light-gray-shifted: #e0e0e0;
$gray: #ccc;
$info: #e6f4ff;
$notice: #fff3cd;
$color-ozon: #005bff;
$color-wb: #cb11ae;
$cyan: #97b0eb;

$color-positive: #7c7;
$color-negative: #f88;
$color-error: #dc3545;

$light-gray: $secondary;
$color-placeholder-bg: $light-gray-shifted;
$color-placeholder: $gray;

$color-group-a: rgba($color-positive, .3);
$color-group-b: $notice;
$color-group-c: rgba($color-negative, .2);

$font-family-sans-serif: 'Montserrat', sans-serif;
$body-bg: $light-gray;
$body-color: #111;

$pagination-disabled-bg: none;
$pagination-bg: none;
$pagination-border-width: 0;

$nav-link-padding-x: .8rem;
$sidenav-width: 280px;
$table-bg: $white;
$table-border-width: 0;
$navbar-padding-y: 1.2rem;
$table-cell-padding: .5rem 1rem;

$tab-border-color: #dee2e6;

$link-color: $primary;


$nav-pills-link-active-color: $black;
$nav-pills-link-active-bg: $light-gray;
$nav-pills-border-radius: 0;

$dropdown-link-hover-bg: $light-gray;

$table-hover-bg: $light-gray;

$h5-font-size: 18px;

$tooltip-bg: $notice;
$tooltip-color: $black;
$tooltip-arrow-color: #fff3cd;
$tooltip-max-width: 500px;

$popover-border-width: 0;
$popover-max-width: 360px;
$popover-border-radius: 15px;
$popover-font-size: 15px;
$popover-body-padding-y: 15px;
$popover-body-padding-x: 20px;

$legend-danger-bg: #ffdad8;

$alert-border-radius: 10px;
$alert-border-width: 0;
$alert-border-radius: 16px;

$card-border-radius: 16px;
$card-border-width: 0;
$transition-collapse: none;

$aggrid-sorted-background-color: #f0f0f0;

$btn-border-radius-sm: .4rem;

$grid-breakpoints-xxs: 430px;
$grid-breakpoints-xxxs: 350px;
$grid-gutter-width: 30px;
$grid-gutter-width-sm: calc($grid-gutter-width / 2);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1680px
);

$tilda-breakpoint-lg: 960px;
$tilda-breakpoint-md: 640px;
$tilda-breakpoint-sm: 480px;
$tilda-breakpoint-xs: 320px;

$date-range-picker-breakpoint: 690px;
$date-range-picker-breakpoint-sm: 610px;
$date-range-picker-breakpoint-xs: 500px;

$sidebar-z-index: 1004;
$intro-modal-z-index: 1049;
$intro-overlay-z-index: 1039;

$modal-content-border-radius: 16px;
$modal-content-box-shadow-xs: 0 2px 10px 0 rgba(136, 136, 136, 0.1);
$modal-content-border-width: 0;

$zindex-modal: 1050;

$badge-font-weight: 400;
