.notification {
  &-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($black, .5);
    z-index: 1100;
    display: none;
  }

  &-suggestion {
    background: $white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 20px 50px ;
    border-radius: 20px 20px 0 0;
    z-index: 1100;
    font-size: 15px;

    li {
      margin-top: 15px;
    }

    p {
      margin-top: 1rem;
    }

    ol {
      margin: auto;
      display: none;
      padding-left: 20px;
    }

    svg {
      vertical-align: middle;
      margin-left: 5px;
      margin-top: -2px;
    }
  }

  &-img {
    text-align: center;
  }

  &-action {
    text-align: center;
    padding-top: 30px;

    .btn {
      border-radius: 30px;
    }
  }
}

.add-to-home-suggest.ios,
.add-to-home-suggest.android {
  display: block;
}

.android .steps-for-android,
.ios .steps-for-ios ol,
.ios .steps-for-ios {
  display: block;
}

.android .animated-arrow {
  display: none;
}

.animated-arrow {
  -webkit-animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite;
  animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite;
  margin-left: -10px;
}

@media (display-mode: standalone) {
  .add-to-home-suggest.ios,
  .add-to-home-suggest.android {
    display: none;
  }

  .standalone-suggest {
    display: block;

    &.hidden {
      display: none;
    }
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
