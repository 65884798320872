.footer {
  font-size: 12px;
  line-height: 1.55;
  background: $mayak-dark;
  color: $mayak-gray-middle;
  padding-top: 60px;
  padding-bottom: 90px;
  overflow: hidden;
  margin-top: 80px;

  .logo-text {
    fill: $white;
  }

  a {
    color: $mayak-gray-middle;
    font-size: 13px;

    &:hover {
      color: $white;
    }
  }

  &__main {
    flex-wrap: nowrap;
    margin-bottom: 2rem;
  }

  &__list {
    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__link-vacancy {
    text-decoration: underline;
  }

  &__mail {
    box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.5);

    &:hover {
      text-decoration: none;
      box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 1);
    }
  }

  p {
    font-weight: 300;

    @include media-breakpoint-down(lg) {
      padding-right: 30px;
    }

    @media (max-width: $tilda-breakpoint-lg) {
      padding-right: 20px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @media (max-width: $tilda-breakpoint-lg) {
    .wrap {
      max-width: 680px;
    }

    &__main {
      flex-wrap: wrap;
    }
  }

  @media (max-width: $tilda-breakpoint-sm) {
    &__main {
      justify-content: center;
      margin-bottom: 1rem;
      position: relative;
      padding-top: 80px;
    }

    &__logo {
      position: absolute;
      left: 0;
      top: 0;
    }

    .wrap {
      max-width: 280px;
      padding-left: 0;
    }
  }
}

.seo-description {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 12px;

  &__body {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);

    a {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);

      &:hover {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
      }
    }
  }
}

.col {
  width: 260px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  p {
    margin-top: 1rem;
  }

  @media (max-width: $tilda-breakpoint-lg) {
    &:nth-child(3) {
      order: 1;
    }

    &:nth-child(4) {
      order: 0;
    }

    &:nth-child(2) {
      order: 4;
      margin-top: -4rem;
    }
  }

  @media (max-width: 600px) {
    width: 220px;
  }

  @media (max-width: $tilda-breakpoint-sm) {
    margin-bottom: 24px;
    width: 280px;

    &:nth-child(2) {
      margin-top: 0;
    }

    &:nth-child(1) {
      order: 1;
    }
  }
}

.col-footer {
  display: flex;
  flex-direction: column;

  svg {
    opacity: 0.8;
  }

  &__residents-icons {
    svg {
      margin-top: 16px;
    }
  }

  &__residents {
    display: flex;
    flex-direction: column;

    span {
      font-size: 16px;
    }
  }

  &__official-wb {
    margin-bottom: 32px;
  }

  @include media-breakpoint-up(xl) {
    justify-content: space-between;
  }

  @media (max-width: $tilda-breakpoint-sm) {
    margin-left: 20px;

    svg {
      margin-right: 8px;
    }

    &__residents-icons {
      display: flex;
      align-items: center;

      svg {
        margin-top: 0;
      }
    }

    &__residents {
      span {
        margin-bottom: 4px;
        font-size: 14px;
      }
    }

    &__official-wb {
      svg {
        width: 200px;
        height: 79px;
      }
    }
  }
}

.social-accounts {
  margin: 1.33rem 0 1.5rem;

  a {
    margin-right: 10px;
    text-decoration: none;
  }

  path {
    fill: $white;
  }

  @media (max-width: $tilda-breakpoint-sm) {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: .5rem;
  }
}

.legal-name {
  p {
    margin: 0;
    margin-top: 1rem;
  }

  @media (max-width: $tilda-breakpoint-sm) {
    p {
      &:first-child {
        margin: 0;
      }
    }
  }
}

.bottom {
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Montserrat", Arial, sans-serif;
  color: #000000;

  h2 {
    font-size: 32px;
    line-height: 1.35;
    font-weight: 700;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
    }

    @media (max-width: $tilda-breakpoint-lg) {
      font-size: 25px;
    }

    @media (max-width: $tilda-breakpoint-md) {
      font-size: 18px;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 600;

    @include media-breakpoint-down(lg) {
      font-size: 20px;
    }

    @media (max-width: $tilda-breakpoint-md) {
      font-size: 16px;
      font-weight: 700;
    }
  }

  ul {
    list-style: none;
  }
}

.chrome {
  background: $white;
  border-radius: 18px;
  padding: 50px 50px 10px;
  margin-bottom: 50px;
  overflow: hidden;

  p {
    font-size: 21px;
    line-height: 1.55;
    font-weight: 500;
  }

  @include media-breakpoint-down(lg) {
    padding: 20px 20px 10px 30px;

    p {
      font-size: 18px;
    }
  }

  @media (max-width: $tilda-breakpoint-md) {
    padding: 10px;

    p {
      font-size: 16px;
    }
  }

  h2,
  p,
  .btn-lg,
  .chrome-features {
    @media (max-width: $tilda-breakpoint-md) and (min-width: 400px) {
      margin-left: 20px;
    }
  }
}

.chrome-preview {
  float: right;
  width: 540px;
  margin-left: 20px;
  margin-bottom: 50px;

  @include media-breakpoint-down(lg) {
    max-width: 500px;
  }

  @media (max-width: $tilda-breakpoint-lg) {
    margin-right: -5%;
  }

  @media (max-width: 860px) {
    margin-right: -200px;
  }

  @media (max-width: 700px) {
    float: none;
    margin: 0 auto 20px;
    max-width: 100%;
  }
}

.chrome-features {
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;

  img {
    float: left;
    margin-right: 15px;

    @media (max-width: $tilda-breakpoint-md) {
      width: 30px;
    }
  }

  li {
    width: 35%;
    padding-right: 10px;

    @include media-breakpoint-down(lg) {
      width: 37%;
    }

    &:last-child {
      width: 30%;

      @include media-breakpoint-down(lg) {
        width: 28%;
      }
    }
  }

  p {
    font-size: 16px;

    @include media-breakpoint-down(lg) {
      margin-right: 15px;
    }
  }

  strong {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
  }

  @include media-breakpoint-down(lg) {
    img {
      margin-right: 10px;
      margin-top: 2px;
    }

    strong {
      font-size: 17px;
      letter-spacing: -0.05ex;
    }
  }

  @media (min-width: $tilda-breakpoint-lg) {
    display: flex;

    p {
      clear: both;
      padding-top: 10px;
    }
  }

  @media (max-width: $tilda-breakpoint-lg) {

    li:last-child,
    li {
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    margin-top: 30px;
  }
}

.feature {
  display: flex;
  margin-top: 30px;

  ul {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.8;
    list-style: none;

    @include media-breakpoint-down(lg) {
      font-size: 17px;
    }

    @media (max-width: $tilda-breakpoint-md) {
      font-size: 16px;
    }
  }

  li {
    position: relative;
    padding-left: 25px;

    &:before {
      content: "—";
      position: absolute;
      left: 0;
      top: 0;
    }

    &:last-child {
      &:before {
        content: "";
      }
    }
  }

  &:last-of-type {
    .feature-preview {
      margin: 0 35px 0 0;
      max-width: 550px;
    }

    @media (max-width: 860px) {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 860px) {
    flex-direction: column;
  }

  @media (max-width: 860px) {
    max-width: 640px;
    margin: auto;
  }
}

.feature-preview {
  max-width: 630px;
  margin-left: 35px;

  img {
    max-width: 100%;
  }

  @include media-breakpoint-down(lg) {
    max-width: 500px;
  }

  @media (max-width: $tilda-breakpoint-lg) {
    max-width: 450px;
  }

  @media (max-width: 860px) {
    max-width: 100%;
    margin: 0;
  }
}

.feature-icon {
  margin-left: 15px;
  margin-top: -70px;
}

.feature-title {
  border-bottom: 1px solid #6c6c6c;
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #000;
  }
}

.feature-info {
  padding-top: 20px;

  @include media-breakpoint-up(xl) {
    padding-top: 40px;
  }
}
