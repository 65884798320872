// New brand settings
$mayak-primary: #ffc700;
$mayak-primary-shifted: lighten(adjust-hue($mayak-primary, 356.4), 4.5);

$mayak-dark: #2E2B36;
$mayak-black: #383540;
$mayak-white: #fff;
$mayak-accent: #007BFB;
$mayak-accent-shifted: darken($mayak-accent, 8.04);
$mayak-danger: #ff3f3f;

$mayak-gray-light: #f7f8fa;
$mayak-gray-middle: #A5A5A5;
$mayak-gray: #6e7788;
$mayak-gray-dark: #423f4a;

$mayak-border-radius: 4px;

$mayak-body-text: $mayak-gray-dark;

$gap: 20px;
$gap-sm: (
  $gap / 2
);
$gap-xs: (
  $gap / 4
);

$radius: 16px;

$font-family: 'ONYOneBeta', sans-serif;

$font-size-small: 12px;
$font-size-regular: 14px;
$font-size-large: 18px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1680px
);
