.btn {
  background: $primary;
  color: $white;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  border: 0;
  min-width: 100px;

  &-link {
    background: none;
    color: $primary;
    padding: 0;
    text-align: left;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba($primary, .3);
  }

  &:active {
    background: darken($primary, 5%);
  }

  &:hover {
    color: $white;
    text-decoration: none;
  }

  &.btn-lg {
    padding: 0 25px;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    text-align: center;
    border-radius: 30px;
    font-weight: 700;
    margin-bottom: 10px;

    @media (max-width: 1200px) {
      height: 50px;
      line-height: 50px;
      padding: 0 25px;
    }

    @media (max-width: 385px) {
      font-size: 16px;
      height: 40px;
      line-height: 40px;
    }

    @media (max-width: 350px) {
      font-size: 15px;
      padding: 0 11px;
    }
  }

  @media (min-width: 330px) {
    min-width: 120px;
  }
}

.fixed .btn-nav {
  background: #005bff;
  color: $white;
  border-radius: 17px;
  padding: 5px 15px;
  height: 33px;
}
