.page-title {
  font-size: 27px;
  line-height: 1.35;
  font-weight: 700;
  margin: 0 0 10px;
  display: inline;

  @media (max-width: 960px) {
    font-size: 22px;
  }

  @media (max-width: 640px) {
    font-size: 18px;
  }
}

.page-subtitle {
  font-size: 18px;
  margin: 0;

  @media (max-width: 640px) {
    display: none;
  }
}

.list-group {
  overflow: hidden;
  list-style-type: none;
  padding: 20px 0;
  margin: 0;

  @media (min-width: 640px) {
    li {
      float: left;
      width: 50%;
      margin-bottom: 10px;
    }  
  }
}

.text-right {
  text-align: right;
}

.text-nowrap {
  white-space: nowrap;
}

.sm-title {
  font-size: 10px;

  @media (max-width: 640px) {
    display: none;
  }
}