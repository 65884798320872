a {
  color: #0D43C4;

  &:hover {
    color: #0C50E9;
  }
}

.form-signin-heading {
  margin-top: 10%;
  line-height: normal;
  font-weight: bold;
  font-size: 1.5rem;
}

.alert {
  color: red;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  margin-bottom: 5px !important;
}

ul.alert {
  list-style-type: none;
  padding-left: 0;
}

.form-signup,
.form-signin {
  min-height: 100%;
  padding: 0 35px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 #dee2e6;

  label {
    color: #000;
    font-weight: 600;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media-breakpoint-down(md) {
    min-width: 300px;
  }

  @include media-breakpoint-down(xs) {
    border-radius: 18px;
    margin: 0 auto;
  }

  @media (max-width: $grid-breakpoints-xxs) {
    min-width: 250px;

    label {
      display: block;
      line-height: normal;
    }
  }
}

.row-md {
  .auth & {
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: stretch;
      min-height: 100%;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.form-control {
  position: relative;
  font-size: 18px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 58px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ced4da;
  display: block;
  border-radius: 3px;
  -webkit-appearance: none;

  &:focus {
    box-shadow: inset 0 0 0 1px #0C50E9;
    border-color: #0C50E9;
    outline: none;
  }

  &:hover {
    border-color: #acb2b8;
  }

  @include media-breakpoint-down(xs) {
    height: 50px;
    font-size: 16px;
  }
}

.select {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOmNjPSJodHRwOi8vY3JlYXRpdmVjb21tb25zLm9yZy9ucyMiIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzb2RpcG9kaT0iaHR0cDovL3NvZGlwb2RpLnNvdXJjZWZvcmdlLm5ldC9EVEQvc29kaXBvZGktMC5kdGQiIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCwtOTUyLjM2MjE4KSI+PHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC10cmFuc2Zvcm06bm9uZTtkaXJlY3Rpb246bHRyO2Jsb2NrLXByb2dyZXNzaW9uOnRiO2Jhc2VsaW5lLXNoaWZ0OmJhc2VsaW5lO2NvbG9yOiMwMDAwMDA7ZW5hYmxlLWJhY2tncm91bmQ6YWNjdW11bGF0ZTsiIGQ9Im0gNDkuOTk5OTcsMTAyMi42OTAzIDQuMTU2MjYsLTQgMjksLTI3Ljk5OTk1IC04LjMxMjUsLTguNjU2MyAtMjQuODQzNzYsMjMuOTk5OTUgLTI0Ljg0MzcsLTIzLjk5OTk1IC04LjMxMjUsOC42NTYzIDI5LDI3Ljk5OTk1IDQuMTU2Miw0IHoiIGZpbGw9IiMwMDAwMDAiIGZpbGwtb3BhY2l0eT0iMSIgc3Ryb2tlPSJub25lIiBtYXJrZXI9Im5vbmUiIHZpc2liaWxpdHk9InZpc2libGUiIGRpc3BsYXk9ImlubGluZSIgb3ZlcmZsb3c9InZpc2libGUiPjwvcGF0aD48L2c+PC9zdmc+");
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: center right 20px;
  padding-right: 45px;
}

.toggle-pass {
  position: absolute;
  z-index: 9;
  right: 10px;
  bottom: 50%;
  margin-bottom: -34px;
  cursor: pointer;
  height: 36px;
  width: 36px;

  &:hover {
    opacity: .6;
  }

  img {
    height: 36px;
    width: 36px;
    margin: 0;
  }

  &.open {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='752pt' height='752pt' version='1.1' viewBox='0 0 752 752'%3E%3Cg%3E%3Cpath d='m376 435.2c32.695 0 59.199-26.504 59.199-59.199s-26.504-59.195-59.199-59.195-59.195 26.5-59.195 59.195 26.5 59.199 59.195 59.199z'/%3E%3Cpath d='m168.88 392.16c-5.5859-10.055-5.5859-22.266 0-32.316 40.406-72.742 118.02-121.97 207.14-121.97 89.117 0 166.73 49.227 207.14 121.97 5.582 10.051 5.582 22.262 0 32.316-40.41 72.742-118.02 121.97-207.14 121.97-89.113 0-166.73-49.227-207.14-121.97zm305.8-16.16c0 54.492-44.172 98.664-98.664 98.664-54.488 0-98.66-44.172-98.66-98.664 0-54.488 44.172-98.66 98.66-98.66 54.492 0 98.664 44.172 98.664 98.66z' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 35px 35px;

    img {
      opacity: 0;
    }
  }
}

.edit-password-form,
.form-signup {
  .form-group {
    margin-bottom: 25px;
    position: relative;

    @media (max-width: $grid-breakpoints-xxs) {
      margin-bottom: 20px;
    }
  }
}

.form-signin {
  .form-group {
    margin-bottom: 35px;
    position: relative;

    @media (max-width: $grid-breakpoints-xxs) {
      margin-bottom: 30px;
    }
  }
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin .error {
  color: darkred;
}

.invalid-feedback {
  color: red;

  a {
    color: inherit;
    font-weight: bold;
  }
}

.wrapper {
  width: 550px;

  @include media-breakpoint-down(md) {
    width: auto;
    max-width: 100%;
    min-width: 340px;
  }

  @include media-breakpoint-down(xs) {
    width: auto;
    max-width: 100%;
    min-width: 300px;
  }

  @include media-breakpoint-up(xl) {
    width: 750px;
  }

  .auth & {
    overflow: hidden;

    &:before {
      content: "";
      width: 5px;
      display: block;
      left: 0;
      top: 0;
      position: fixed;
      bottom: 0;
      background: #0C50E9;
    }
  }

  .compact-form & {
    margin: auto;

    @include media-breakpoint-down(md) {
      max-width: 550px;
    }

    @include media-breakpoint-down(xs) {
      margin: 0 10px;
    }
  }
}

.hint {
  font-size: 15px;
  color: #777;
  line-height: 1.2;
  margin-top: 1rem;

  @include media-breakpoint-down(xs) {
    margin-top: 0;
  }
}

.logo {
  text-decoration: none;

  .compact-form & {
    margin: 0 auto 15px;
  }

  @media (max-width: $grid-breakpoints-xxs) {
    .auth & {
      float: left;
    }
  }

  &-sm {
    @include media-breakpoint-up(xs) {
      height: 45px;
      width: auto;
      margin-bottom: 10px;
      margin-top: -20px;
    }

    @media (max-width: $grid-breakpoints-xxs) {
      height: 35px;
      margin-bottom: 15px;
    }
  }
}


.inline-logo {
  float: left;
  margin-right: 15px;
  margin-top: 10px;

  @include media-breakpoint-down(xs) {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    margin-top: 0;
  }
}

.form-signup-heading {
  margin: 20px 0 0 0;
  line-height: 32px;
  font-size: 30px;
  color: #000;
  font-weight: bold;

  @include media-breakpoint-down(xs) {
    font-size: 20px;
    margin-top: 0;
  }
}

.links {
  font-weight: 600;
}

.secondary-action {
  float: right;
}

.robot_last_name {
  display: none;
}

@include media-breakpoint-down(xs) {
  .form-signin-heading {
    font-size: 20px;
  }
}

@media (max-width: $grid-breakpoints-xxs) {
  .navigation {
    position: absolute;
    top: 20px;
    right: 15px;
  }

  .form-signup,
  .form-signin {
    padding: 5px 15px 15px;
  }

  .hint {
    margin-top: 0;
  }

  .form-signin-heading {
    margin-top: 10px;
    font-size: 1.3em;
  }

  .form-signin {
    .form-signin-heading {
      margin-bottom: 0;
    }

    p {
      margin-top: 5px;
      line-height: normal;
    }
  }
}

.auth {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background: $light-gray;
  min-height: 100%;
  height: 100%;
}

.signup-notes {
  text-align: left;
  background: #fff;
  padding: 25px 35px 25px;
  border-top: solid 1px #ced4da;
  border-radius: 0 0 18px 18px;
  font-size: 17px;
  box-shadow: 0 5px 8px 0 #dee2e6;
  margin-bottom: 20px;

  ul {
    margin: 0;
    padding: 0 0 0 20px;
  }

  p {
    margin: 0;
  }

  @include media-breakpoint-down(md) {
    padding: 25px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 16px;
    padding: 15px;
  }
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  margin-bottom: .25rem;

  &-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }

  &-label {
    margin-bottom: 0;
    font-weight: 400;
  }
}

.form-actions {
  padding-top: 1rem;
}

.edit-password-form {
  text-align: left;
  margin: 10px auto 20px;
  min-height: 100%;
  padding: 0 35px 30px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 #dee2e6;
  max-width: 550px;
  border-radius: 18px;

  @include media-breakpoint-down(xs) {
    margin: 0 10px;
    padding: 0 20px 30px;

    .form-signin-heading {
      margin-top: 20px;
    }
  }
}
