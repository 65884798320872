.inline-toggle {
  position: relative;
  padding-right: 16px;
  cursor: pointer;
  color: #0C50E9;
  white-space: nowrap;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 0;
    width: 16px;
    height: 16px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiLz48L3N2Zz4=');
    background-position: center right -2px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }

  &:hover {
    color: #000;
    text-decoration: none;
  }

  &.dropdown {
    white-space: nowrap;
  }
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 0 18px 0 rgba($black, .1);
  font-size: 18px;
}

.dropdown-item {
  padding: .5rem 1.5rem;
}
