header {
  height: 80px;
  margin-bottom: 30px;
  padding-top: 23px;

  img {
    width: 105px;
  }

  .fixed & {
    position: fixed;
    background: $white;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0 0 18px 0 rgba($black, .1);
    z-index: 999;
  }

  .wrap {
    @media (max-width: 600px) {
      padding: 0 10px;
    }
  }

  @media (max-width: 700px) {
    height: 70px;
    padding-top: 18px;
    margin-bottom: 10px;
  }
}

.fixed {
  padding-top: 100px;
}

.header-nav {
  float: right;

  a {
    margin-left: 30px;
    text-decoration: none;
    color: $black;
    font-size: 16px;
    display: inline-block;
    font-weight: 600;

    @media (max-width: 960px) {
      margin-left: 20px;
    }

    @media (max-width: 640px) {
      margin-left: 0;
      margin-right: 10px;
    }

    @media (max-width: 360px) {
      font-size: 15px;

      .fixed & {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  @media (min-width: 700px) {
    padding-right: 20px;
  }

  @media (max-width: 640px) {
    margin-top: 5px;

    .download {
      display: none;
    }
  }

  @media (max-width: 400px) {
    .rates {
      display: none;
    }
  }

  .fixed & {
    margin-top: 0;
  }
}
